import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import BannerSlider from '../Element/BannerSlider';
import GallerySlider from '../Element/GallerySlider';

import icon1 from './../../images/icon/icon1.jpg';
import icon2 from './../../images/icon/icon2.jpg';
import icon3 from './../../images/icon/icon3.jpg';
import icon4 from './../../images/icon/icon4.jpg';
import bgimg1 from './../../images/line.png';
import bnr3 from './../../images/background/bg6.jpg';

const iconBlog = [
	{ image: icon1, title1: 'Child centered environment', title2: '& Hands on Learning' },	
	{ image: icon2, title1: 'Developing communication', title2:' & critical thinking skills' },	
	{ image: icon3, title1: 'Developing sound values', title2: '& sensitivity' },	
	{ image: icon4, title1: 'Focus on individual progress', title2:'& development' },	
];
const iconBlog2 = [
	{ icon: <i className="flaticon-rattle text-blue" />, title: 'Enquiry-Based Learning', desc: 'It enables children to develop research skills, social skills, increase their knowledge about many topics and most importantly, it develops a love of learning.' },	
	{ icon: <i className="flaticon-bricks text-green" />, title: 'Multiple Intelligences', desc: 'The objectives are fulfilled through the use of puppets, role play, circle time activities, art and craft, action songs.' },	
	{ icon: <i className="flaticon-puzzle text-orange" />, title: 'Age-Appropriate Activities', desc: 'To enhance childrens fine and gross motor skills, language skills, special awareness, mathematical skills, along with developing skills and values like, task completion, focus and independence.'  },	
];


class Index1 extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					{ /* Slider Banner */ }
						<BannerSlider />
					{ /* Slider Banner */ }
					<div className="content-block">
						{/*  About Us */}
							<div className="section-full bg-white content-inner-1 text-center">
								<div className="container">
									<div className="section-head">
									<h2 className="head-title text-secondry">Welcome to Suvarnashree Gurukul</h2>
										<p>Fill your child's childhood with the joy of learning!</p>
									</div>
									<div className="row">
										{iconBlog.map((data, index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
												<div className="icon-bx-wraper sr-iconbox m-b20">
													<div className="icon-lg m-b20">
														<Link to={"#"} className="icon-cell"><img src={data.image} alt=""/></Link>
													</div>
													<div className="icon-content">
														<h6 className="dlab-tilte" >{data.title1}<br/>{data.title2}</h6>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						{/*  About Us End*/}
						<div className="section-full bg-white content-inner-2 about-box" style={{backgroundImage:"url(" + bgimg1 +")",  backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-7 col-md-12 col-sm-12 col-12">
										<div className="section-head">
											<h2 className="head-title text-secondry">Our Vision</h2>
											<p>
												To instill life-long values that nurture humanity, create global citizens and give shape to a better world. To serve humanity with spirituality.
											</p>
											<h2 className="head-title text-secondry">Our Mission</h2>
											<p>
												• To promote holistic development of children. <br/>
												• Emphasize the need for play-based and joyful learning.<br />
												• To prepare children for formal schooling.<br />
												• To get maximum involvement from parents. <br />
												• We aim to transform society by awakening humanity in people.<br />
												• To promote Indian culture and heritage.<br />
											</p>
											<Link to={"/contact-us"} className="btn btn-md radius-xl" >Learn more</Link>
										</div>
									</div>
									<div className="col-lg-5 col-md-12 col-sm-12 col-12">
										{iconBlog2.map((item, index)=>(
											<div className="icon-bx-wraper left" key={index}>
												<div className="icon-lg m-b20"> <span className="icon-cell">{item.icon}</span> </div>
												<div className="icon-content">
													<h2 className="dlab-tilte">{item.title}</h2>
													<p>{item.desc}</p>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					
						<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
							<BackgroundBlog2 />
						</div>
						<div className="section-full bg-white content-inner-1">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Gallery of our classes</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<GallerySlider />
							</div>
						</div>
						
						{/* <div className="section-full bg-white content-inner-1" style={{backgroundImage:"url("+ bgimg3 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">About the Teachers</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TeacherSlider />
							</div>
						</div> */}
						{/* <div className="section-full bg-white content-inner-1">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials about center</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TestiMonialSlider />
							</div>
						</div> */}
						{/* <div className="section-full bg-white content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">From the Blog</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<FromSlider />
							</div>
						</div> */}
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}
}
function BackgroundBlog2() {
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text" style={{ visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn" }}>
						<div className="section-head text-center">
							<h4 className="text-white">Join Our New Session</h4>
							<h2>Call To Enrol Your Child <br /><span className="text-yellow">+91 8861262041</span></h2>
							<Link to={"/contact-us"} className="btn btn-md radius-xl">Read More</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export { BackgroundBlog2};
export default Index1;
import React,{Fragment, Component} from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import bnr1 from './../../images/line2.png';
import team1 from './../../images/team/pic1.jpg';
import team2 from './../../images/team/pic2.jpg';
import team3 from './../../images/team/pic3.jpg';
import team4 from './../../images/team/pic4.jpg';
import team5 from './../../images/team/pic5.jpg';
import team6 from './../../images/team/pic6.jpg';
import team7 from './../../images/team/pic7.jpg';
import team8 from './../../images/team/pic8.jpg';

const teamBlog = [
	{image: team1 ,}, {image: team2 ,}, {image: team3 ,},
	{image: team4 ,}, {image: team5 ,}, {image: team6 ,},
	{image: team7 ,}, {image: team8 ,}, {image: team3 ,},
	{image: team4 ,}, {image: team5 ,}, {image: team6 ,},
];

class AboutSwamiji extends Component{
	render(){
		return(
			<Fragment>
				<Header />
					<div className="page-content">
					<PageTitle motherMenu="About Swamiji" activeMenu="About Swamiji" />
						<div className="content-block">
							<div className="section-full bg-white content-inner" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
								<div className="container">
								<div className="section-head text-center widget widget_archive widget-title">
									<h2 className="head-title text-secondry " style={{fontSize: "40px"}}>Vision of <br/>
										Shri Sha. Bra. Dr. Mahantalinga Shivacharya Mahaswamij</h2>
									<h3 className="text-secondry">The founder of S V S Free Residential School</h3>
									
									</div>
								
									<ul>
										<li> Presently the text books knowledge has failed to increase the moral values and hence Shri Swamiji gives top priority to enhance the moral fibre of the younger generations.</li>
										<p></p>
										<li>Believes in potentiality of rural area students</li>
										<p></p>
										<li>To give free education with accommodation to the rural students of poor families of all communities.</li>
										<p></p>
										<li>To prepare the students to become responsible citizens by inculcating in them morality, honesty, integrity, patriotism, social and ethical values, sense of discipline and commitment to make this world peaceful and harmonious.</li>
										<p></p>
										<li>To give quality education with computer, yoga, sports facilities and to be impacted by qualified and trained teachers.</li>
										<p></p>
										<li>Public are exhorted to donate generously in the realisations of a great cause undertaken by Shri Sha. Bra. Dr. Mahantalinga Shivacharya Mahaswamiji.</li>
									</ul>

								
								<div className="section-head text-center widget widget_archive widget-title">
									<h2 className="head-title text-secondry" style={{ fontSize: "40px" }}>
										Shri Sha. Bra. Dr. Mahantalinga Shivacharya Mahaswamij</h2>
									<h3 className="text-secondry">Mathadipathi, Shrimad Vibhuthipura Veerasimhasana Samsthan Math, Vibhuthipura, Marathahalli Post, Bangalore - 560 037</h3>

								</div>
								
								<h5 >
									❖ Birth 
								</h5>
								
								<ul>
									<li>Date:- 01-06-1976 at Sankanahala Village, Basavana Bagevadi (Tq), Vijayapur (Dist)</li>
									<p></p>
									<li>Father’s Name:- Basalingayya</li>
									<p></p>
									<li>Mother’s Name:- Shanthabai</li>
									
								</ul>
									
									<h5>
										❖ Education
								</h5>
								
								<ul>
									<li>Primary education: - Hunshayal School, Near Birth Place.</li>
									<p></p>
									<li>High School education:- R P A High School Rajaji Nagar, Bangalore.</li>
									<p></p>
									<li>PUC: - Shree Jagdguru Renukacharya collage passed out with first rank.</li>
									
								</ul>
										
									<h5>
									❖ Graduation
								</h5>
								
								<ul>
									<li>B. A :- National Collage, Basavanagudi, Bangalore.</li>
									<p></p>
									<li>Post Graduation (M A) :- In Sanskrit. Obtained 3 Gold Medals from Bangalore University.</li>
									<p></p>
									<li>Phd : - In 2011 Bangalore University presented Doctorate degree for the thesis in the subject “Shyvagamagalu and Shri Siddantha Shikamani”.</li>
								</ul>
									
									<h5>
									❖ Spiritual life
									</h5>
								<ul>
									<li>Param Pujya SwamiJi began a spiritual life at the very young age of sixteen. In 1992, from Vijayapura district, Basavana Bagewadi Taluk, Tapobhushan Shri Shri Shri Sanganabasava Shivacharya Mahaswamiji, who was Matadipathi of Managuli, Pattikanthi Hiremath accepted Dr. Mahaanta Linga Shivacharya Swamiji as successor of the matha.</li>
									<p></p>
									<li>In 1996 Shri Shri Shri Suvarnalinga shivacharya mahaswamiji of Shrimad vibhutipura Veerasimhasana Samsthanam Bengaluru, accepted Mahaanta Linga shivacharya Swamiji as his successor.</li>
									<p></p>
									<li>In 2001 after the demise of Shri Suvarnalinga shivacharya mahaswamiji he became the whole time pattadhikari and started his service.</li>
											
								</ul>
									<h5>
									❖ Progress of Sri Matha and social servicess
								</h5>
								
								<ul>
									<li>Under the leadership of Param Pujya SwamiJi Shrimad vibhutipura Matha has gained a new strength. As a result of sacrifices and hard work of Swamiji today Shri Matha owns a huge Kalyana Mantapa in the memory of “Sri Jagadguru Panchacharya”. The income earned from the Kalyana Mantapa is being utilised for the Free Residential School of shri manta.</li>
									<p></p>
									<li>The Prominence of “No fees, No donation school” in Bangalore goes to Dr. Mahaanta Linga shivacharya Swamiji. the free residential English medium school started in the year 2009, aims to provide the free education for the poor and talented children from the remote villages of Karnataka, for the classes from 6th to 10th standard. Every year school is securing cent percent result in SSLC.</li>
									<p></p>
									<li>To help the needy, Shri matha has come up with the charitable hospital, dialysis centre and old age home named “Sandya Sukhadama”. This reflects Param Pujya SwamiJi concern towards the society.</li>
									<p></p>
									<li>Total renovation of Sri Veerabhadra Swamy temple of Shri Matha, the Santhrupti Sadana, the place where Dharma Chinthana is held every month, Guru Sadhana, Atithi Sadhana, ShivYoga Sadhana, has taken place. Added to this Shri Matha is also feathered with residential, commercial complexes, Veda and Sanskrit Pathshala and industrial training centre within the premises of shri Manta. With all those a land of about 12.5 acres has been purchased in the name of Shri Mata on Bangalore to Tumkur highway road.</li>
								</ul>
								

									<h5>
									❖  Escalation of Spiritual Knowledge
								</h5>
								
								<ul>
									<li>Param Pujya SwamiJi with his great experience and knowledge has thrown light on the philosophy of spirituality in veerashaiva Siddhant, not only in India but also in countries like Australia, New Zealand, Russia, Singapore and Oman.</li>
									<p></p>
									<li>Shrimad vibhutipura Matha has retrieved its Glory with the leadership of ParamPujya SwamiJi. With Swamiji being the great orator, has earned lot of respect and love from the general public.</li>
									<p></p>
									<li>For the past 15 years Swamiji has been Spreading the essence of “Sanatana Dharma”, through “Mane Managalalli Shivapuje Shivanubhav and Shri Siddanta Shikhamani Adhyatma Pravachana”, Which will be held on every day bases in Shravana Masa.</li>
								</ul>
									
								</div>
							</div>
						</div>
					</div>
				<Footer />
			</Fragment>
		)
	}
}

export default AboutSwamiji;